Sider {
    flex: 0 0 250px;
    max-width: 225px!important;
    min-width: 225px!important;
    width: 250px;
}

.popover {
    padding: 20px;
    border-radius: 2px;
    border: solid #efefef 0.5px;
    background: white;
}

.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 29px;
    padding: 2px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
    margin-top: 5px;
}

.logo {
    height: 30px;
    margin: 20px;
    display: flex;
    background-image: url("../../assets/images/pnta_mapping_logo_cmyk_logo-visual.png");
    background-size: 180px;
    background-repeat: no-repeat;

}

.Layout {
    background-color: black;
}

.Footer {
    z-index: 0;
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    background-color: black;
    padding-top: 0.1px;
}

.ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 45px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    background-color: #d9232e;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: black;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d9232e;
}

.site-layout .site-layout-background {
    background: #090909;
}
[data-theme="dark"] .site-layout .site-layout-background {
    background: black;
}

.inProgress{
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -300px;    
    background-color: yellow;
    color: #090909;
    font-size: large;
    font-weight: bold;
    z-index: 3000;
    border-radius: 5px;
    
}

.inProgress div{
    width: 600px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
