.Avatar {
    left: 5px;
    width: auto;
    height: 95px;
    position: center;
    top: 5px;
    object-fit: contain;
    margin: 5px;
}

.Paper {
    margin-top: 8px;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}

.Form {
    width: 100%;
    margin-top: 8px;
}

.Submit {
    margin: 3px 0px 2px;
}

.Logo {
    height: 75px;
    display: flex;
    background-image: url("../../assets/images/pntalogo.png");
    background-size: 300px;
    background-repeat: no-repeat;
}