@font-face {
        font-family: "roboto-regular";
        src: url("./assets/fonts/roboto-regular.ttf") format("truetype");
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.Header {
    z-index: inherit;
}

.Sider {
    z-index: auto;
}