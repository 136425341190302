.map {
    width: calc(100vw - 18px);
    height: calc(100vh - 18px);
    border: 1px solid black;
}

.GeoSegment {
    /* fill: rgba(185,15,130,1); */
    transition: all .5s;
    stroke-width: 2px;
}
.GeoSegment:hover {
    stroke-width: 4px;
    /* fill: rgba(185,15,130,1); */
    cursor: crosshair;
}